import "./App.css";

function App() {
	return (
		<div className="App">
			<header className="App-header">
				<h1>Website is under maintance check back soon!</h1>
				<h2>Thanks Franklyn C</h2>
			</header>
		</div>
	);
}

export default App;
